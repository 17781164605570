<template>
  <div>
    <div class="facilityBox" v-loading="flag.loadCardList">
      <div class="chooseWrap">
        <div style="display: flex;justify-content: end;align-content: center;">
          <el-tooltip style="margin-bottom: 8px;" placement="left">
            <div slot="content">
              1.选择您想开展的业务<br />
              2.每个独立的业务，都有独立的流程、针对性的风控、专业的服务<br />
            </div>
            <div style="margin: 0px 20px 8px;color: #0F5FFF;cursor: pointer;">
              <i class="el-icon-question"></i>
              <span>帮助</span>
            </div>
          </el-tooltip>
        </div>

        <!-- 卡片列表 -->
        <div class="row" v-for="(item, index) in cardList" :key="index">
          <div class="title">{{ index }}</div>
          <div class="cards">
            <div class="card" v-for="(el, inx) in item" :key="inx">
              <img :src="el.ImgURL" @click="goWaybill(el)" class="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import { getCardList } from "@/api/waybill/businessChoose";
import { mapMutations } from "vuex";
export default {
  mixins: [basicMixin],
  data() {
    return {
      flag: {
        loadCardList: false, //加载卡片中
      },
      cardList: [], //卡片列表
    };
  },
  methods: {
    ...mapMutations(["setWaybillType"]),
    //去运单上传第一步
    goWaybill(item) {
      if (!item.Enable) {
        this.$message.success("暂未开放，敬请期待");
        return;
      }
      this.setWaybillType({
        taskType: item.TaskTypeName,
        taskTypeId: item.TaskTypeID,
        rateType: item.InvoiceType,
        sceneType: item.TaskSceneName,
        sceneId: item.TaskSceneTypeId,
      });
      this.$router.push(item.Ext1);
      // this.$router.push({path:'waybillRepository'})
    },
    getCardList() {
      this.flag.loadCardList = true;
      getCardList()
        .then((res) => {
          this.cardList = this._.groupBy(res.Choose, "InvoiceType");
        })
        .finally(() => {
          this.flag.loadCardList = false;
        });
    },
  },

  created() {
    this.getCardList();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/variable.scss";
.facilityBox {
  .chooseWrap {
    .row {
      .title {
        color: #2081ff;
        position: relative;
        text-indent: 15px;
        font-size: 14px;
        padding: 20px 0;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 3px;
          height: 14px;
          border-radius: 5px;
          background: $primary;
        }
      }
      .cards {
        display: flex;
        flex-wrap: wrap;
        .card {
          height: 130px;
          margin: 0 50px 30px 30px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          transition: all 0.4s;
          &:hover {
            transform: scale(1.1);
          }
          cursor: pointer;
          img {
            width: 250px;
          }
        }
      }
    }
  }
}
</style>
